$primary: #1fc36a;
$primary1: #1fc36a;
$primary2: #216869;
$primary3: #5c4ae4;
$gray1: #444444;
$gray2: #666666;
$gray3: #888888;
$gray4: #cccccc;
$gray5: #eeeeee;
$gray6: #efefef;

$break-sm: 576px;
$break-md: 768px;
$break-lg: 992px;
$break-xl: 1200px;



.order-card {
	a {
		color: #444;
	}
	.order-number {
		font-weight: 800;
		font-size: 1.25em;
		padding-right: 15px;
	}
	.order-status {
		text-transform: uppercase;
		font-size: 0.85em;
	}
	.address,
	.phone {
		font-size: 0.85em;
	}
	.qty {
		padding-right: 10px;
		color: #888;
		font-size: 0.85em;
	}
	.price {
		font-weight: 600;
		font-size: 1.25em;
	}
	.label {
		display: inline-block;
		width: 100%;
		text-transform: uppercase;
		color: #888;
		font-size: 0.85em;
		white-space: nowrap;
	}
	.order-resp {
		font-size: 1em;
		font-weight: 600;
	}
	.view {
		display: inline-block;
		padding: 5px 10px;
		border-radius: 4px;
		background: #c31f78;
		color: white;
		font-weight: 600;
		text-align: center;
	}

	@media (max-width: $break-md) {
		.view {
			width: 100%;
		}
	}
}

